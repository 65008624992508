import moment from "moment";
import React, { Component, useEffect } from "react";
import { Linking, StyleSheet, View, Platform, Dimensions } from "react-native";
import { ActivityIndicator, DefaultTheme, Icon, PaperProvider, Portal, Text } from "react-native-paper";
import { de, registerTranslation } from "react-native-paper-dates";
import { ToastProvider } from "react-native-toast-notifications";
import Toast from "react-native-toast-notifications";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import "vanilla-cookieconsent/dist/cookieconsent.css";
import * as CookieConsent from "vanilla-cookieconsent";

import Config from "./Config";
import MainLayout from "./src/layouts/MainLayout";
import { PrivateRoute } from "./src/routers/PrivateRoute";
import { Redirect, Route, Router, Switch, useLocation } from "./src/routers/routing";
import CookingTerminal from "./src/screens/cookingTerminal/CookingTerminal";
import CustomerAddresses from "./src/screens/customerScreens/CustomerAddresses";
import CustomerProfile from "./src/screens/customerScreens/CustomerProfile";
import OrderTracking from "./src/screens/orderTracking/OrderTracking";
import AddToCartDialog from "./src/screens/shop/addToCartDialog/AddToCartDialog";
import AddToWeeklyPlanDialog from "./src/screens/shop/addToWeeklyPlanDialog/AddToWeeklyPlanDialog";
import FullCategory from "./src/screens/shop/FullCategory";
import ProductDetails from "./src/screens/shop/ProductDetails";
import Shop from "./src/screens/shop/Shop";
import ShoppingList from "./src/screens/shoppingList/ShoppingList";
import WeeklyPlan from "./src/screens/weeklyPlan/WeeklyPlan";
import NotFound from "./src/screens/notFound/NotFound";
import {
    checkIfArticleSizeHasPrices,
    desktopBreakpoint,
    getContrastColor,
    getPriceOfArticleSize,
} from "./src/shared/helpers";
import { styles } from "./src/shared/styles";
import { getCustomer, setCustomer, createCustomerInBIOS } from "./src/store/actions/accountActions";
import { ADD_ORDER_ITEM } from "./src/store/actions/cartActions";
import { addRecipesToWeeklyPlan, updateCookingProcess } from "./src/store/actions/onlineShopCategoriesActions";
import { getActiveShopTheme, getSettings } from "./src/store/actions/settingsActions";
import { persistor, store } from "./src/store/store";
import DataProtectionPolicy from "./src/screens/shop/DataProtectionPolicy";
import Imprint from "./src/screens/shop/Imprint";
import WithdrawalNotice from "./src/screens/shop/WithdrawalNotice";
import TermsAndConditions from "./src/screens/shop/TermsAndConditions";
import * as Sentry from "sentry-expo";
import CookieConsentTheme from "./src/helpers/cookieConsent/CookieConsentTheme";
import SessionExpiredDialog from "./src/screens/sessionExpiredDialog/sessionExpiredDialog";
import { setLogoutFlag } from "./src/store/actions/uiActions";
import CookingMonitorDialog from "./src/screens/cookingMonitorDialog/CookingMonitorDialog";
import Keycloak from "keycloak-js";
import keycloak from "./src/helpers/keycloakConfig/KeycloakConfig";
import ShippingCosts from "../ggbiosshop/src/screens/shop/ShippingCosts";
import { checkAndRefreshToken } from "./src/helpers/sessionHandling/tokenHelper";
import { showSessionExpiredDialog } from "./src/store/actions/uiActions";
import OrderHistory from "./src/screens/customerScreens/OrderHistory";

const backendConfig = new Config();

registerTranslation("de", de);

moment.locale("de");

const ScrollToTop = () => {
    const location = useLocation();

    if (location.pathname !== "/") {
        const scrollY = window.scrollY;
        localStorage.setItem("scrollY", JSON.stringify(scrollY));
    }

    useEffect(() => {
        if (location.pathname === "/") {
            const storedPosition = JSON.parse(localStorage.getItem("scrollY"));
            setTimeout(() => {
                window.scrollTo(0, storedPosition);
            }, 200);
        }
    }, [location.pathname]);

    return null;
};

export default class App extends Component {
    constructor(props) {
        super(props);

        if (Platform.OS === "web") {
            require("./src/shared/web.css");
        }

        this.state = {
            windowWidth: Dimensions.get("window").width,
            windowHeight: Dimensions.get("window").height,
            theme: {
                ...DefaultTheme,
                // version: 2,
                dark: true,
                roundness: 2,
            },
            loading: true,
            addToWeeklyPlanVisible: false,
            addToCartVisible: false,
            selectedCustomerCourse: undefined,
            selectedArticleSize: undefined,
            selectedRecipe: undefined,
            selectedCookingProcess: undefined,
            selectedArticle: undefined,
            customerUid: undefined,
            portalVisible: false,
            datePickerVisible: true,
            selectedDate: new moment(),
            currentTask: undefined,
            errorText: undefined,
            redirectCounter: 3,
            isAuthenticated: false,
            error: "",
            user: {},
            mobileMenuOpen: false,
            cartOpen: false,
            customerUid: "",
            paymentLink: "",
            headerHeight: "0px",
            cookieContent: CookieConsent.getCookie(),
            showCookieButton: false,
            authLoading: false,
            showCookingMonitorDialog: false,
            cookingMonitorUrl: "",
            keycloakInitialized: false,
            redirectUri: window.location.origin + window.location.pathname,
            sessionExpired: false,
        };

        this.updateDimensions = this.updateDimensions.bind(this);
        this.toggleAddToWeeklyPlanDialog = this.toggleAddToWeeklyPlanDialog.bind(this);
        this.showAddRecipeToWeeklyPlanPopup = this.showAddRecipeToWeeklyPlanPopup.bind(this);
        this.addRecipeToWeeklyPlan = this.addRecipeToWeeklyPlan.bind(this);
        this.toggleAddToCartDialog = this.toggleAddToCartDialog.bind(this);
        this.showAddArticleToCartPopup = this.showAddArticleToCartPopup.bind(this);
        this.addArticleToCart = this.addArticleToCart.bind(this);
        this.toggleCart = this.toggleCart.bind(this);
        this.toggleCustomerData = this.toggleCustomerData.bind(this);
        this.setLoading = this.setLoading.bind(this);
        this.setRedirectCounter = this.setRedirectCounter.bind(this);
        this.login = this.login.bind(this);
        this.logout = this.logout.bind(this);
        this.updateCookingProcess = this.updateCookingProcess.bind(this);
        this.toggleMobileMenu = this.toggleMobileMenu.bind(this);
        this.updateCustomer = this.updateCustomer.bind(this);
        this.cookRecipe = this.cookRecipe.bind(this);
        this.initializeCookieConsent = this.initializeCookieConsent.bind(this);
        this.initializeSentry = this.initializeSentry.bind(this);
        this.cookieConsentChanged = this.cookieConsentChanged.bind(this);
        this.updateHeaderHeight = this.updateHeaderHeight.bind(this);
        this.toggleCookingMonitorDialog = this.toggleCookingMonitorDialog.bind(this);
        this.initializeKeycloak = this.initializeKeycloak.bind(this);
        this.checkTokenStatus = this.checkTokenStatus.bind(this);
        this.handleTokenExpiration = this.handleTokenExpiration.bind(this);

        // Binden der Methoden an die Instanz
        this.setTokenCheckInterval = this.setTokenCheckInterval.bind(this);
        this.clearTokenCheckInterval = this.clearTokenCheckInterval.bind(this);
        this.checkTokenStatus = this.checkTokenStatus.bind(this);
    }

    async updateCustomer() {
        if (keycloak) {
            await store.dispatch(getCustomer(keycloak.token)).then((response) => {
                let customer = {
                    customerUid: response.customerUid,
                    // azureId: response.azureId,
                    firstName: response.firstName,
                    familyName: response.familyName,
                    addresses: response.addresses,
                    title: response.title,
                    email: response.email,
                    phonenumbers: response.phonenumbers,
                    customerCourses: response.customerCourses,
                };
                store.dispatch(setCustomer(customer));
            });
        }
    }

    login() {
        if (!this.state.isAuthenticated) {
            keycloak.login();
        }
    }

    logout() {
        keycloak.logout();
        localStorage.removeItem("customerUid");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");
        store.dispatch(setCustomer(undefined));
    }

    initializeSentry() {
        if (!window.SentryInitialized) {
            Sentry.init({
                dsn: backendConfig.sentryDSN,
                enableInExpoDevelopment: false,
                debug: false,
                tracesSampleRate: 1.0,
                tracePropagationTargets: ["expo", "browser", "react-native"],
                normalizeDepth: 10,
                integrations: [new Sentry.React.BrowserTracing(), new Sentry.React.Replay()],
            });
            window.SentryInitialized = true;
        }
    }

    cookieConsentChanged() {
        this.setState({ cookieContent: CookieConsent.getCookie(), showCookieButton: true });
    }

    initializeCookieConsent() {
        /**
         * All config. options available here:
         * https://cookieconsent.orestbida.com/reference/configuration-reference.html
         */
        CookieConsent.run({
            categories: {
                necessary: {
                    enabled: true,
                    readOnly: true,
                },
                analytics: {},
            },

            onFirstConsent: () => {
                this.cookieConsentChanged();
            },

            onConsent: () => {
                this.cookieConsentChanged();
            },

            onChange: () => {
                this.cookieConsentChanged();
            },

            language: {
                default: "de",
                translations: {
                    de: {
                        consentModal: {
                            title: "Diese Webseite nutzt Cookies",
                            description:
                                "Diese Webseite nutzt Cookies zur Verbesserung des Erlebnisses unserer Besucher. Einige dieser Cookies sind technisch zwingend notwendig, um gewisse Funktionen der Webseite zu gewährleisten. Darüber hinaus verwenden wir einige Cookies, die dazu dienen, Informationen über das Benutzerverhalten auf dieser Webseite zu gewinnen und unsere Webseite auf Basis dieser Informationen stetig zu verbessern.",
                            acceptAllBtn: "Alle akzeptieren",
                            acceptNecessaryBtn: "Nur essentielle Cookies akzeptieren",
                            showPreferencesBtn: "Individuelle Einstellungen verwalten",
                        },
                        preferencesModal: {
                            title: "Cookie Einstellungen verwalten",
                            acceptAllBtn: "Alle akzeptieren",
                            acceptNecessaryBtn: "Nur essentielle Cookies akzeptieren",
                            savePreferencesBtn: "Aktuelle Auswahl akzeptieren",
                            closeIconLabel: "Fenster schließen",
                            sections: [
                                {
                                    title: "Somebody said ... cookies?",
                                    description: "I want one!",
                                },
                                {
                                    title: "technisch notwendige Cookies",
                                    description:
                                        "Diese Cookies sind für das reibungslose Funktionieren der Website unerlässlich und können nicht deaktiviert werden.",

                                    linkedCategory: "necessary",
                                },
                                {
                                    title: "Performance und Analytics",
                                    description:
                                        "Diese Cookies sammeln Informationen darüber, wie Sie unsere Website nutzen. Alle Daten sind anonymisiert und können nicht dazu verwendet werden, Sie zu identifizieren.",
                                    linkedCategory: "analytics",
                                },
                                {
                                    title: "Weitere Informationen",
                                    description:
                                        'Sie können mehr über die von uns genutzten Cookies und relevanten Services in unserer <a href="/datenschutzbestimmung">Datenschutzbestimmung</a> erfahren',
                                },
                            ],
                        },
                    },
                },
            },
        });
    }

    async initializeKeycloak() {
        // check if already initialized
        if (this.state.keycloakInitialized) {
            console.log("Keycloak bereits initialisiert");
            return;
        }

        try {
            // normal initialization
            const authenticated = await keycloak.init({
                onLoad: "check-sso",
                silentCheckSsoRedirectUri:
                    process.env.NODE_ENV === "production" ? window.location.origin + "/silent-check-sso.html" : null,
                redirectUri: this.state.redirectUri,
                enableLogging: true, // for better debugging
            });

            this.setState({ keycloakInitialized: true });

            if (authenticated) {
                // save token after successful authentication
                localStorage.setItem("accessToken", keycloak.token);
                localStorage.setItem("refreshToken", keycloak.refreshToken);

                this.setState({
                    isAuthenticated: true,
                    authLoading: false,
                });

                // start token check
                this.setTokenCheckInterval();

                try {
                    let response = await store.dispatch(getCustomer(keycloak.token));
                    console.log("response", response);
                    if (response.status === 404) {
                        try {
                            let createCustomerResponse = await store.dispatch(createCustomerInBIOS(keycloak));
                        } catch (error) {
                            throw new Error(`Failed to create customer: ${error}`);
                        }

                        response = await store.dispatch(getCustomer(keycloak.token));
                    }

                    if (response && response.customerUid) {
                        store.dispatch(setCustomer(response));

                        const keycloakUserInfo = await keycloak.loadUserInfo();
                        const userUid = keycloakUserInfo.sub;
                        this.setState({ customerUid: userUid });
                        localStorage.setItem("customerUid", userUid);
                    } else {
                        throw new Error("Failed to fetch customer information.");
                    }
                } catch (error) {
                    console.error("Failed to process customer information:", error);
                    this.handleTokenExpiration();
                }
            } else {
                this.setState({
                    isAuthenticated: false,
                    authLoading: false,
                });
            }
        } catch (error) {
            console.error("Failed to initialize Keycloak:", error);
            this.handleTokenExpiration();
        }
    }

    setTokenCheckInterval() {
        this.clearTokenCheckInterval(); // Zuerst das bestehende Intervall löschen

        if (this.state.isAuthenticated) {
            this.tokenCheckInterval = setInterval(async () => {
                try {
                    const success = await this.checkTokenStatus();
                    if (!success) {
                        this.handleTokenExpiration();
                    }
                } catch (error) {
                    console.error("Token check failed:", error);
                    this.handleTokenExpiration();
                }
            }, 3600000);
        }
    }

    clearTokenCheckInterval() {
        if (this.tokenCheckInterval) {
            clearInterval(this.tokenCheckInterval);
        }
    }

    checkTokenStatus = async () => {
        if (!this.state.isAuthenticated || !keycloak.token) {
            return false;
        }

        try {
            // Versuche Token zu aktualisieren wenn weniger als 5 Minuten übrig sind
            const updated = await keycloak.updateToken(300);

            if (updated) {
                localStorage.setItem("accessToken", keycloak.token);
                localStorage.setItem("refreshToken", keycloak.refreshToken);
                return true;
            }

            return false;
        } catch (error) {
            console.error("Token refresh failed:", error);
            return false;
        }
    };

    handleTokenExpiration = () => {
        this.setState({
            isAuthenticated: false,
            sessionExpired: true,
        });
        store.dispatch(setCustomer(undefined));
        localStorage.removeItem("customerUid");
        localStorage.removeItem("accessToken");
        localStorage.removeItem("refreshToken");

        // Das Overlay wird erst beim nächsten Intervall angezeigt
        // store.dispatch(showSessionExpiredDialog());
    };

    componentDidMount() {
        this.initializeKeycloak();
        store.dispatch(getSettings()).then((res) => {
            if (res) {
                this.setState({ loading: false, theme: res });

                if (Platform.OS === "web") {
                    let faviconPath;
                    if (res.faviconUrl) {
                        faviconPath = backendConfig.imageStorageBlobURL + res.faviconUrl;
                    } else if (res.logoUrl) {
                        faviconPath = backendConfig.imageStorageBlobURL + res.logoUrl;
                    }

                    if (faviconPath) {
                        this.setFavicon(faviconPath);
                    }
                }

                if (res.title) {
                    document.title = res.title;
                }
            }
        });
        Dimensions.addEventListener("change", this.updateDimensions);

        if (Platform.OS === "web") {
            this.initializeCookieConsent();
        } else {
            this.initializeSentry();
        }
        if (!this.isAuthenticated) {
            setTimeout(() => {
                store.dispatch(setLogoutFlag(false));
            }, 250);
        }

        // Token-Status-Überprüfung alle 30 Sekunden
        this.setTokenCheckInterval();
    }

    componentDidUpdate(prevProps, prevState) {
        const { cookieContent, isAuthenticated } = this.state;
        if (cookieContent && cookieContent.categories && cookieContent.categories.includes("analytics")) {
            this.initializeSentry();
        }

        // Setzen Sie das Intervall immer neu, wenn der Benutzer authentifiziert ist
        if (isAuthenticated) {
            this.setTokenCheckInterval();
        } else {
            // Wenn der Benutzer nicht mehr authentifiziert ist, löschen Sie das Intervall
            this.clearTokenCheckInterval();
        }
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
        this.clearTokenCheckInterval();
    }

    updateDimensions() {
        this.setState({
            windowWidth: Dimensions.get("window").width,
            windowHeight: Dimensions.get("window").height,
        });
    }

    updateHeaderHeight = (height) => {
        this.setState({ headerHeight: height });
    };

    setFavicon(faviconPath) {
        const timestamp = new Date().getTime();

        const links = document.querySelectorAll(
            'link[rel="icon"], link[rel="shortcut icon"], link[rel="apple-touch-icon"], link[rel="apple-touch-startup-image"]'
        );
        links.forEach((link) => link.parentNode.removeChild(link));

        const link = document.createElement("link");
        link.rel = "icon";
        link.type = "image/x-icon";
        link.href = `${faviconPath}?v=${timestamp}`;
        document.head.appendChild(link);

        const shortcutIcon = document.createElement("link");
        shortcutIcon.rel = "shortcut icon";
        shortcutIcon.type = "image/x-icon";
        shortcutIcon.href = `${faviconPath}?v=${timestamp}`;
        document.head.appendChild(shortcutIcon);

        const appleTouchIcon = document.createElement("link");
        appleTouchIcon.rel = "apple-touch-icon";
        appleTouchIcon.sizes = "180x180";
        appleTouchIcon.href = `${faviconPath}?v=${timestamp}`;
        document.head.appendChild(appleTouchIcon);

        const startupImageSizes = [
            { width: 320, height: 568, ratio: 2 },
            { width: 414, height: 896, ratio: 3 },
            { width: 414, height: 896, ratio: 2 },
            { width: 375, height: 812, ratio: 3 },
            { width: 414, height: 736, ratio: 3 },
            { width: 375, height: 667, ratio: 2 },
            { width: 1024, height: 1366, ratio: 2 },
            { width: 834, height: 1194, ratio: 2 },
            { width: 834, height: 1112, ratio: 2 },
            { width: 768, height: 1024, ratio: 2 },
        ];

        startupImageSizes.forEach(({ width, height, ratio }) => {
            const appleStartupImage = document.createElement("link");
            appleStartupImage.rel = "apple-touch-startup-image";
            appleStartupImage.media = `screen and (device-width: ${width}px) and (device-height: ${height}px) and (-webkit-device-pixel-ratio: ${ratio}) and (orientation: portrait)`;
            appleStartupImage.href = `${faviconPath}?v=${timestamp}`;
            document.head.appendChild(appleStartupImage);
        });
    }

    toggleAddToWeeklyPlanDialog() {
        var visibility = !this.state.addToWeeklyPlanVisible;
        document.body.style.overflow = visibility ? "hidden" : "auto";
        this.setState({
            addToWeeklyPlanVisible: visibility,
            portalVisible: visibility,
        });
    }

    toggleAddToCartDialog() {
        var visibility = !this.state.addToCartVisible;
        document.body.style.overflow = visibility ? "hidden" : "auto";
        this.setState({
            addToCartVisible: visibility,
            portalVisible: visibility,
        });
    }

    toggleCookingMonitorDialog() {
        this.setState((prevState) => ({
            showCookingMonitorDialog: !prevState.showCookingMonitorDialog,
        }));
    }

    showAddRecipeToWeeklyPlanPopup(
        selectedRecipe,
        customerUid,
        selectedCookingProcess,
        selectedArticleSize = undefined
    ) {
        document.body.style.overflow = "hidden";
        this.setState({
            selectedRecipe: selectedRecipe,
            addToWeeklyPlanVisible: true,
            customerUid: customerUid,
            portalVisible: true,
            selectedCookingProcess: selectedCookingProcess,
            startCookingProcessImmediately: false,
            selectedArticleSize: selectedArticleSize,
        });
    }

    showAddArticleToCartPopup(selectedArticle, customerUid, orderType, priceGroupsId, selectedArticleSize = undefined) {
        document.body.style.overflow = "hidden";
        this.setState({
            selectedArticle: selectedArticle,
            addToCartVisible: true,
            customerUid: customerUid,
            portalVisible: true,
            orderType: orderType,
            priceGroupsId: priceGroupsId,
            selectedArticleSize: selectedArticleSize,
        });
    }

    addRecipeToWeeklyPlan(customerCoursesId, articleSizesId, recipesId, dayToCook, amount, urlBasePath) {
        this.setState({
            addToWeeklyPlanLoading: true,
        });

        var recipesToWeeklyPlanDto = {
            customersUid: this.state.customerUid,
            RecipesForWeeklyPlan: [
                {
                    recipesId: recipesId,
                    articleSizesId: articleSizesId,
                    amount: amount,
                    dayToCook: dayToCook,
                    customerCourse: customerCoursesId,
                },
            ],
        };
        store.dispatch(addRecipesToWeeklyPlan(recipesToWeeklyPlanDto, keycloak)).then((result) => {
            this.setState({
                selectedRecipe: undefined,
                addToWeeklyPlanLoading: false,
            });
            this.toggleAddToWeeklyPlanDialog();
            // if (result.cookingProcessId > 0) {
            //     if (this.state.startCookingProcessImmediately) {
            //         // window.open(
            //         //     "https://demo.bios.gluecklichegaeste.de/#/guest/allCookingProcessesCustomer/" +
            //         //         this.state.customerUid +
            //         //         "/c/" +
            //         //         result.cookingProcessId
            //         // );
            //         this.setState(
            //             {
            //                 cookingProcessId: result.cookingProcessId,
            //                 urlBasePath: urlBasePath,
            //             },
            //             () => {
            //                 document.getElementById("popupLink").click();
            //             }
            //         );
            //     }
            // } else {
            //     alert("Fehler beim Anlegen des Kochprozesses");
            // }
            if (result.message) {
                if (this.state.startCookingProcessImmediately) {
                    let cookingMonitorUrl = urlBasePath;
                    if (!cookingMonitorUrl.startsWith("https://")) {
                        cookingMonitorUrl = "https://" + cookingMonitorUrl;
                    }
                    cookingMonitorUrl +=
                        "guest/allCookingProcessesCustomer/" + this.state.customerUid + "/c/" + result.message;

                    this.setState({
                        cookingMonitorUrl,
                        showCookingMonitorDialog: true,
                    });
                }
            } else {
                alert("Fehler beim Anlegen des Kochprozesses");
            }
        });
    }

    updateCookingProcess(customerCoursesId, articleSizesId, dayToCook, amount, cookingProcessId) {
        var updateCookingProcessDto = {
            customersUid: this.state.customerUid,
            articleSizesId: articleSizesId,
            amount: amount,
            dayToCook: dayToCook,
            customerCourse: customerCoursesId,
            cookingProcessId: cookingProcessId,
        };
        store.dispatch(updateCookingProcess(updateCookingProcessDto, keycloak)).then((result) => {
            this.setState({
                addToWeeklyPlanVisible: false,
                selectedCookingProcess: undefined,
                portalVisible: false,
            });
        });
    }

    addArticleToCart(
        articleSize,
        article,
        amount,
        optionalOrderType = this.state.orderType,
        optionalPriceGroupsId = this.state.priceGroupsId
    ) {
        const articleCopy = { ...article, amount: amount };

        const orderItem = {
            entry: articleCopy,
            ArticleSize: articleSize,
            Price: getPriceOfArticleSize(articleSize, optionalOrderType.orderTypesId, optionalPriceGroupsId),
            orderType: optionalOrderType,
            dayToCook: new moment(),
        };

        document.body.style.overflow = "auto";
        store.dispatch({ type: ADD_ORDER_ITEM, orderItem });
        this.setState({
            addToCartVisible: false,
            selectedArticle: undefined,
            portalVisible: false,
        });
    }

    toggleCart() {
        var visibility = !this.state.cartOpen;
        this.setState({
            cartOpen: visibility,
            portalVisible: visibility,
        });
    }

    toggleMobileMenu() {
        var visibility = !this.state.mobileMenuOpen;
        this.setState({
            mobileMenuOpen: visibility,
            portalVisible: visibility,
        });
    }

    toggleCustomerData() {
        var visibility = !this.state.customerDataOpen;
        document.body.style.overflow = visibility ? "hidden" : "auto";
        this.setState({
            customerDataOpen: visibility,
            portalVisible: visibility,
            cartOpen: false,
        });
    }

    setLoading(currentTask, error, paymentLink = "") {
        this.setState({
            currentTask: currentTask,
            errorText: error,
            orderProcessingActive: currentTask || error,
            portalVisible: true,
            paymentLink: paymentLink,
        });
    }

    setRedirectCounter(counter) {
        if (counter >= 0)
            this.setState({
                redirectCounter: counter,
            });
        else this.setState({ portalVisible: false, redirectCounter: 3 });
    }

    cookRecipe(selectedRecipe, customerUid, selectedArticleSize = undefined) {
        document.body.style.overflow = "hidden";
        this.setState({
            selectedRecipe: selectedRecipe,
            addToWeeklyPlanVisible: true,
            customerUid: customerUid,
            portalVisible: true,
            startCookingProcessImmediately: true,
            selectedArticleSize: selectedArticleSize,
        });
    }

    dismissOrderProcessing = () => {
        this.setState({ orderProcessingActive: false });
    };

    setAuthState = (state) => {
        this.setState(state);
    };

    render() {
        const { theme, loading, windowWidth, showCookingMonitorDialog, cookingMonitorUrl } = this.state;
        const isMobile = windowWidth <= desktopBreakpoint;

        return (
            <Provider store={store}>
                <ToastProvider>
                    <Toast ref={(ref) => (global["toast"] = ref)} />
                    <PersistGate loading={null} persistor={persistor}>
                        <Router>
                            <PaperProvider theme={theme}>
                                {!this.state.loading && <SessionExpiredDialog login={this.login} />}
                                {Platform.OS === "web" && <CookieConsentTheme theme={this.state.theme} />}
                                {/* // This button is only displayed in the web version and is not relevant for the app version, so no react native code is required here  */}
                                {Platform.OS === "web" &&
                                    this.state.showCookieButton &&
                                    !this.state.loading &&
                                    !isMobile && (
                                        <button
                                            className="showCookieConsentDesktopButton"
                                            data-cc="showPreferences"
                                            onClick={() => CookieConsent.showPreferences()}
                                            type="button"
                                            style={{
                                                backgroundColor: theme.colors.primary,
                                                color: getContrastColor(theme.colors.primary),
                                            }}
                                        >
                                            <Icon
                                                source="cookie"
                                                color={getContrastColor(theme.colors.primary)}
                                                size={12}
                                            />
                                            <span>Cookie Einstellungen</span>
                                        </button>
                                    )}

                                <View
                                    style={{
                                        ...StyleSheet.flatten(styles.mainContainer),
                                        backgroundColor: theme.colors.background,
                                    }}
                                >
                                    <View
                                        style={
                                            this.state.portalVisible
                                                ? styles.screenContainerPortal
                                                : styles.screenContainer
                                        }
                                    >
                                        {!this.state.loading && (
                                            <MainLayout
                                                toggleLoginPopup={this.login}
                                                logout={this.logout}
                                                toggleCart={this.toggleCart}
                                                toggleMobileMenu={this.toggleMobileMenu}
                                                toggleCustomerData={this.toggleCustomerData}
                                                cartOpen={this.state.cartOpen}
                                                mobileMenuOpen={this.state.mobileMenuOpen}
                                                customerDataOpen={this.state.customerDataOpen}
                                                setLoading={this.setLoading}
                                                setRedirectCounter={this.setRedirectCounter}
                                                redirectCounter={this.state.redirectCounter}
                                                updateCustomer={this.updateCustomer}
                                                updateHeaderHeight={this.updateHeaderHeight}
                                                showCookieButton={this.state.showCookieButton}
                                                authLoading={this.state.authLoading}
                                                isAuthenticated={this.state.isAuthenticated}
                                                setTokenCheckInterval={this.setTokenCheckInterval}
                                                clearTokenCheckInterval={this.clearTokenCheckInterval}
                                            >
                                                <ScrollToTop />
                                                <Switch>
                                                    <Route
                                                        path="/"
                                                        exact
                                                        render={() => (
                                                            <Shop
                                                                showAddRecipeToWeeklyPlanPopup={
                                                                    this.showAddRecipeToWeeklyPlanPopup
                                                                }
                                                                showAddArticleToCartPopup={
                                                                    this.showAddArticleToCartPopup
                                                                }
                                                                cookRecipe={this.cookRecipe}
                                                                onlineShopItemType={-1}
                                                                toggleLoginPopup={this.login}
                                                            />
                                                        )}
                                                    />
                                                    <Route
                                                        path="/shop"
                                                        exact
                                                        render={() => (
                                                            <Shop
                                                                showAddRecipeToWeeklyPlanPopup={
                                                                    this.showAddRecipeToWeeklyPlanPopup
                                                                }
                                                                showAddArticleToCartPopup={
                                                                    this.showAddArticleToCartPopup
                                                                }
                                                                cookRecipe={this.cookRecipe}
                                                                onlineShopItemType={0}
                                                                toggleLoginPopup={this.login}
                                                            />
                                                        )}
                                                    />
                                                    <Route
                                                        path="/recipes"
                                                        exact
                                                        render={() => (
                                                            <Shop
                                                                showAddRecipeToWeeklyPlanPopup={
                                                                    this.showAddRecipeToWeeklyPlanPopup
                                                                }
                                                                showAddArticleToCartPopup={
                                                                    this.showAddArticleToCartPopup
                                                                }
                                                                cookRecipe={this.cookRecipe}
                                                                onlineShopItemType={1}
                                                                toggleLoginPopup={this.login}
                                                            />
                                                        )}
                                                    />
                                                    <PrivateRoute
                                                        path="/wochenplan"
                                                        exact
                                                        render={() => (
                                                            <WeeklyPlan
                                                                showAddRecipeToWeeklyPlanPopup={
                                                                    this.showAddRecipeToWeeklyPlanPopup
                                                                }
                                                            />
                                                        )}
                                                    />
                                                    <PrivateRoute
                                                        path="/cookingTerminal"
                                                        exact
                                                        component={CookingTerminal}
                                                    />
                                                    <PrivateRoute
                                                        path="/shoppingList"
                                                        exact
                                                        render={() => <ShoppingList />}
                                                    />
                                                    <Route
                                                        path="/category/:categoryName/:id/:backend*"
                                                        exact
                                                        render={() => (
                                                            <FullCategory
                                                                showAddRecipeToWeeklyPlanPopup={
                                                                    this.showAddRecipeToWeeklyPlanPopup
                                                                }
                                                                showAddArticleToCartPopup={
                                                                    this.showAddArticleToCartPopup
                                                                }
                                                                cookRecipe={this.cookRecipe}
                                                                toggleLoginPopup={this.login}
                                                            />
                                                        )}
                                                    />
                                                    <Route
                                                        path="/ProductDetails/:productName/:type/:id/:backend*"
                                                        exact
                                                        render={() => (
                                                            <ProductDetails
                                                                showAddRecipeToWeeklyPlanPopup={
                                                                    this.showAddRecipeToWeeklyPlanPopup
                                                                }
                                                                showAddArticleToCartPopup={
                                                                    this.showAddArticleToCartPopup
                                                                }
                                                                cookRecipe={this.cookRecipe}
                                                                addArticleToCart={this.addArticleToCart}
                                                                toggleLoginPopup={this.login}
                                                                headerHeight={this.state.headerHeight}
                                                            />
                                                        )}
                                                    />
                                                    <PrivateRoute
                                                        path="/customer"
                                                        exact
                                                        render={() => <Redirect to="/customer/profile/" />}
                                                    />
                                                    <PrivateRoute
                                                        path="/customer/addresses"
                                                        exact
                                                        render={() => (
                                                            <CustomerAddresses
                                                                update={this.updateCustomer}
                                                                showBackButton={true}
                                                                windowWidth={windowWidth}
                                                            />
                                                        )}
                                                    />
                                                    <PrivateRoute
                                                        path="/customer/profile"
                                                        exact
                                                        render={() => (
                                                            <CustomerProfile
                                                                update={this.updateCustomer}
                                                                windowWidth={windowWidth}
                                                                toggleCustomerData={this.toggleCustomerData}
                                                            />
                                                        )}
                                                    />
                                                    <PrivateRoute
                                                        path="/customer/orderHistory"
                                                        exact
                                                        render={() => <OrderHistory windowWidth={windowWidth} />}
                                                    />
                                                    <Route
                                                        path="/orderTracking/:id?/:paymentSuccessful?/:returnString?"
                                                        exact
                                                        render={(props) => (
                                                            <OrderTracking {...props} windowWidth={windowWidth} />
                                                        )}
                                                    />
                                                    <Route
                                                        path="/datenschutz"
                                                        exact
                                                        render={() => (
                                                            <DataProtectionPolicy windowWidth={windowWidth} />
                                                        )}
                                                    />
                                                    <Route
                                                        path="/impressum"
                                                        exact
                                                        render={() => <Imprint windowWidth={windowWidth} />}
                                                    />
                                                    <Route
                                                        path="/disclaimer"
                                                        exact
                                                        render={() => <WithdrawalNotice windowWidth={windowWidth} />}
                                                    />
                                                    <Route
                                                        path="/allgemeinegeschaeftsbedingungen"
                                                        exact
                                                        render={() => <TermsAndConditions windowWidth={windowWidth} />}
                                                    />
                                                    <Route
                                                        path="/versandkosten"
                                                        exact
                                                        render={() => <ShippingCosts windowWidth={windowWidth} />}
                                                    />
                                                    {/* <Route path="/404" status={404} exact component={NotFound} /> */}
                                                    <Route
                                                        path="/404"
                                                        exact
                                                        render={({ staticContext }) => {
                                                            if (staticContext) {
                                                                staticContext.statusCode = 404;
                                                            }
                                                            return <NotFound />;
                                                        }}
                                                    />

                                                    <Route path="*" component={NotFound} />
                                                </Switch>
                                            </MainLayout>
                                        )}
                                    </View>
                                </View>
                                <Portal>
                                    <View
                                        style={{
                                            maxHeight: "100vh",
                                            position: "fixed",
                                            top: 0,
                                            width: "100%",
                                            height: "100%",
                                            pointerEvents: "none",
                                        }}
                                    >
                                        {this.state.currentTask && (
                                            <Portal
                                                visible={this.state.orderProcessingActive}
                                                dismissable={true}
                                                onDismiss={this.dismissOrderProcessing}
                                            >
                                                {this.state.portalVisible && this.state.redirectCounter > 0 && (
                                                    <View style={styles.orderProcessingOverlayContainer}>
                                                        <View style={styles.orderProcessingOverlay}>
                                                            <Text style={{ fontSize: 18 }}>
                                                                {this.state.currentTask}...
                                                                {this.state.redirectCounter < 3
                                                                    ? this.state.redirectCounter
                                                                    : ""}
                                                                {this.state.paymentLink && (
                                                                    <Text
                                                                        style={{ color: "blue" }}
                                                                        onPress={() => {
                                                                            const url = this.state.paymentLink;

                                                                            if (Platform.OS === "web") {
                                                                                window.location.href = url;
                                                                            } else {
                                                                                Linking.canOpenURL(url)
                                                                                    .then((canOpen) => {
                                                                                        if (canOpen) {
                                                                                            Linking.openURL(url);
                                                                                        } else {
                                                                                            console.log(
                                                                                                "Cannot open URL: " +
                                                                                                    url
                                                                                            );
                                                                                        }
                                                                                    })
                                                                                    .catch((err) => {
                                                                                        console.log(err);
                                                                                    });
                                                                            }

                                                                            this.setState({
                                                                                orderProcessingActive: false,
                                                                            });
                                                                        }}
                                                                    >
                                                                        {" hier"}
                                                                    </Text>
                                                                )}
                                                            </Text>
                                                            <ActivityIndicator style={{ marginTop: 15 }} size="large" />
                                                        </View>
                                                    </View>
                                                )}
                                            </Portal>
                                        )}
                                        {this.state.orderProcessingActive && this.state.errorText && (
                                            <View style={styles.orderProcessingOverlayContainer}>
                                                <View style={styles.orderProcessingOverlay}>
                                                    <Text
                                                        variant="headlineMedium"
                                                        style={[styles.overlayErrorText, { color: theme.colors.error }]}
                                                    >
                                                        {this.state.errorText}
                                                    </Text>
                                                    <View
                                                        style={[
                                                            styles.overlayErrorIconContainer,
                                                            { backgroundColor: theme.colors.error },
                                                        ]}
                                                    >
                                                        <Icon
                                                            source="exclamation-thick"
                                                            color={getContrastColor(theme.colors.error)}
                                                            size={20}
                                                        />
                                                    </View>
                                                </View>
                                            </View>
                                        )}
                                        {loading && (
                                            <View
                                                style={{
                                                    ...StyleSheet.flatten(styles.loadingContainer),
                                                    backgroundColor: "#FFFFF",
                                                }}
                                            >
                                                <ActivityIndicator size="large" />
                                            </View>
                                        )}
                                        {this.state.selectedRecipe && (
                                            <AddToWeeklyPlanDialog
                                                addToWeeklyPlanVisible={this.state.addToWeeklyPlanVisible}
                                                toggleAddToWeeklyPlanDialog={this.toggleAddToWeeklyPlanDialog}
                                                selectedRecipe={this.state.selectedRecipe}
                                                selectedCookingProcess={this.state.selectedCookingProcess}
                                                loading={this.state.addToWeeklyPlanLoading}
                                                articleSizes={
                                                    this.state.selectedRecipe
                                                        ? this.state.selectedRecipe.articleSizes
                                                        : undefined
                                                }
                                                recipesId={this.state.selectedRecipe ? this.state.selectedRecipe.id : 0}
                                                addRecipeToWeeklyPlan={this.addRecipeToWeeklyPlan}
                                                updateCookingProcess={this.updateCookingProcess}
                                                startCookingProcessImmediately={
                                                    this.state.startCookingProcessImmediately
                                                }
                                                selectedArticleSize={this.state.selectedArticleSize}
                                            />
                                        )}
                                        {this.state.selectedArticle && (
                                            <AddToCartDialog
                                                addToCartVisible={this.state.addToCartVisible}
                                                toggleAddToCartDialog={this.toggleAddToCartDialog}
                                                selectedArticle={this.state.selectedArticle}
                                                articleSizes={
                                                    this.state.selectedArticle
                                                        ? this.state.selectedArticle.articleSizes.filter(
                                                              (articleSize) =>
                                                                  checkIfArticleSizeHasPrices(
                                                                      articleSize,
                                                                      this.state.orderType.orderTypesId,
                                                                      this.state.priceGroupsId
                                                                  )
                                                          )
                                                        : []
                                                }
                                                articlesId={
                                                    this.state.selectedArticle ? this.state.selectedArticle.id : 0
                                                }
                                                addArticleToCart={this.addArticleToCart}
                                                selectedArticleSize={this.state.selectedArticleSize}
                                            />
                                        )}

                                        {showCookingMonitorDialog && (
                                            <CookingMonitorDialog
                                                visible={showCookingMonitorDialog}
                                                cookingMonitorUrl={cookingMonitorUrl}
                                                toggleOverlay={this.toggleCookingMonitorDialog}
                                            />
                                        )}
                                    </View>
                                </Portal>
                            </PaperProvider>
                        </Router>
                    </PersistGate>
                </ToastProvider>
            </Provider>
        );
    }
}
