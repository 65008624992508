import { StyleSheet } from "react-native";

export const orderTrackingStyles = StyleSheet.create({
    row: {
        flexDirection: "row",
    },
    rowLabel: {
        flex: 1,
    },
    rowLabelText: {
        fontWeight: "bold",
    },
    cartRow: {
        flexDirection: "row",
        justifyContent: "space-between",
    },
    sumToPayRow: {
        flexDirection: "row",
        justifyContent: "space-between",
    },
    discountRow: {
        flexDirection: "row",
        justifyContent: "space-between",
        marginTop: 4,
        borderTopWidth: 1,
    },
    articleTitle: {
        flexDirection: "row",
    },
    amount: {
        marginRight: 5,
    },
    sumToPayText: {
        fontWeight: "bold",
    },
    sumToPayValue: {
        fontWeight: "bold",
    },
    billingAddress: {
        marginTop: 10,
    },
    headline: {
        marginBottom: 10,
    },
    text: {
        marginVertical: 2,
    },
});
