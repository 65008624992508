class Config {
    constructor() {
        this.currencyFormat = "0[.]00 $";
        this.imageStorageBlobURL = "https://ggbiosmedia.blob.core.windows.net/ggbiosmedia/";
        this.sentryDSN = "https://3499dbfa1b7a1e5b9c6a38b264125d3e@sentry.foodgenius.de/7";
        //this.apiUrl = "http://localhost:7071/v1-dev";
        //"https://biosshop-dev.azurewebsites.net/api" main Endpoint
        this.apiUrl =
            process.env.NODE_ENV === "development"
                ? "https://functions.gluecklichegaeste.de/v1"
                : "https://functions.gluecklichegaeste.de/v1-dev";
        this.shopModule = "shop";
        this.cashregisterModule = "cashregister";
    }

    get backendHost() {
        let url = new URL(window.location.href);
        if (process.env.NODE_ENV === "development") {
            return "http://localhost:5000/b";
        } else {
            return url.origin + "/b";
        }
    }

    get shopUrl() {
        let url = new URL(window.location.href);
        if (process.env.NODE_ENV === "development") {
            return "http://localhost:19006/";
        } else {
            return url.origin;
        }
    }

    get licmanUrl() {
        if (process.env.NODE_ENV === "development") {
            return "dev.licman.gluecklichegaeste.de";
        } else {
            return window._env_.REACT_APP_LICMAN_URL;
        }
    }

    get licenseNumber() {
        if (process.env.NODE_ENV === "development") {
            return "EF5UE-BJC8Q-UQCBZ-583AV-PAXYN";
        } else {
            return window._env_.REACT_APP_LICENSE_NUMBER;
        }
    }

    get biosShopToken() {
        if (process.env.NODE_ENV === "development") {
            return "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJLZXlWYXVsdE5hbWUiOiJCSU9TVmF1bHQtQklPU0RldiIsIlJlYWxtIjoiZGV2IiwieEFwaUtleSI6IiNiZXImI1MhV154SCQ0eTdvVlZ4OVJFYTUkRFdSVXF2In0.rWGsrd0sX0-g8eTKo_EHd6oa0xs6n2xhSiIu9LP_PqI";
        } else {
            return window._env_.REACT_APP_BIOS_SHOP_TOKEN;
        }
    }

    get keycloakRealm() {
        if (process.env.NODE_ENV === "development") {
            return "dev";
        } else {
            return window._env_.REACT_APP_REALM_NAME;
        }
    }

    get keycloakClientId() {
        if (process.env.NODE_ENV === "development") {
            return "dev_test_frontend";
        } else {
            return window._env_.REACT_APP_KEYCLOAK_CLIENTID;
        }
    }
}

export default Config;
