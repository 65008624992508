import React, { Component } from "react";
import { Animated, Dimensions, Pressable, ScrollView, StyleSheet, View } from "react-native";
import { Button, Portal, withTheme, Text, Icon } from "react-native-paper";
import { connect } from "react-redux";
import { styles } from "../../shared/styles";
import { cartStyles } from "./CartStyles";
import CartItem from "./CartItem";
import { store } from "../../store/store";
import { REMOVE_ORDER_ITEM, loadCartItems } from "../../store/actions/cartActions";
import { desktopBreakpoint, getContrastColor } from "../../shared/helpers";

function removeOrderItem(orderItem) {
    store.dispatch({ type: REMOVE_ORDER_ITEM, orderItem });
}

function sortByDayToCook(a, b) {
    return new Date(a.dayToCook) - new Date(b.dayToCook);
}

class Cart extends Component {
    constructor(props) {
        super(props);
        const { deliveryCountry, isDefaultCountry } = this.getDeliveryCountry();
        this.state = {
            windowWidth: Dimensions.get("window").width,
            windowHeight: Dimensions.get("window").height,
            isLoadingCart: false,
            deliveryCountry: deliveryCountry,
            isDefaultCountry: isDefaultCountry,
        };

        this.widthValue = new Animated.Value(0);

        this.slideIn = this.slideIn.bind(this);
        this.slideOut = this.slideOut.bind(this);
        this.updateDimensions = this.updateDimensions.bind(this);
    }

    componentDidMount() {
        Dimensions.addEventListener("change", this.updateDimensions);
    }

    componentDidUpdate(prevProps) {
        const { open } = this.props;
        if (open && !prevProps.open) {
            this.slideIn();
        } else if (!open && prevProps.open) {
            this.slideOut();
        }
    }

    getDeliveryCountry() {
        const addresses = this.props.account.customer?.addresses;
        const countries = this.props.settings.store.countries;
        let countryId = "";
        if (addresses && addresses.some((addr) => addr.isDefaultDeliveryAddress)) {
            const address = addresses.find((address) => address.isDefaultDeliveryAddress && address.countryId);
            countryId = address?.countryId;
        }

        if (countryId) {
            return {
                isDefaultCountry: false,
                deliveryCountry: countries?.find((country) => country.countryId === countryId),
            };
        } else {
            return {
                isDefaultCountry: true,
                deliveryCountry: countries?.find((country) => country.isDefault),
            };
        }
    }

    updateDimensions() {
        this.setState({
            windowWidth: Dimensions.get("window").width,
            windowHeight: Dimensions.get("window").height,
        });
    }

    slideIn() {
        const { isDefaultCountry, deliveryCountry } = this.getDeliveryCountry();

        this.setState(
            { isLoadingCart: true, deliveryCountry: deliveryCountry, isDefaultCountry: isDefaultCountry },
            () => {
                Animated.timing(this.widthValue, {
                    toValue: 1,
                    duration: 300,
                    useNativeDriver: false,
                }).start(() => {
                    store.dispatch(loadCartItems());
                    this.setState({ isLoadingCart: false });
                });
            }
        );
    }

    slideOut() {
        const { isDefaultCountry, deliveryCountry } = this.getDeliveryCountry();
        this.setState(
            { isLoadingCart: true, deliveryCountry: deliveryCountry, isDefaultCountry: isDefaultCountry },
            () => {
                Animated.timing(this.widthValue, {
                    toValue: 0,
                    duration: 300,
                    useNativeDriver: false,
                }).start(() => {
                    store.dispatch(loadCartItems());
                    this.setState({ isLoadingCart: false });
                });
            }
        );
    }

    calculateTotal() {
        let total = 0;

        if (this.props.cart.orderItems.length > 0) {
            let i = 0;

            while (this.props.cart.orderItems[i]) {
                const orderItem = this.props.cart.orderItems[i];

                if (orderItem) {
                    const price = orderItem.Price ? orderItem.Price.price1 : 0;
                    const { amount } = orderItem.entry;

                    if (price && amount) {
                        total += price * amount;
                    }
                }

                i++;
            }
        }

        total = Math.round(total * 100) / 100;

        return total;
    }

    calculateMinOrderCharge() {
        const { deliveryCountry } = this.state;
        const total = this.calculateTotal();

        const minOrderValue = Number(deliveryCountry.minOrderValue);
        const minOrderCharge = Number(deliveryCountry.minOrderCharge);

        if (this.props.settings.store.dynamicMinOrderCharge) {
            return total < minOrderValue ? (minOrderValue - total).toFixed(2) : 0;
        } else {
            return total < minOrderValue && minOrderCharge ? minOrderCharge.toFixed(2) : 0;
        }
    }

    render() {
        const { open, onBackdropPress, theme, settings, account } = this.props;
        const { windowWidth, windowHeight, isLoadingCart, deliveryCountry, isDefaultCountry } = this.state;
        const dynamicWidth = windowWidth <= 800 ? windowWidth : 375;

        const cartWidth = this.widthValue.interpolate({
            inputRange: [0, 1],
            outputRange: [0, dynamicWidth],
        });

        const minOrderCharge = this.calculateMinOrderCharge();
        const total = this.calculateTotal();
        const showMinOrderWarning = Number(deliveryCountry.minOrderValue) && minOrderCharge === 0 ? true : false;

        return (
            <Portal>
                <Animated.View
                    style={[
                        cartStyles.containerWrapper,
                        {
                            backgroundColor: theme.colors.primary,
                            height: windowHeight,
                            minHeight: windowHeight,
                            width: cartWidth,
                        },
                    ]}
                >
                    {open && !isLoadingCart && deliveryCountry && (
                        <View
                            style={[
                                cartStyles.container,
                                {
                                    height: windowHeight,
                                    minHeight: windowHeight,
                                },
                            ]}
                        >
                            {windowWidth <= desktopBreakpoint && (
                                <Pressable
                                    onPress={() => {
                                        onBackdropPress();
                                        this.slideOut();
                                    }}
                                    style={[
                                        cartStyles.cartCloseButton,
                                        {
                                            backgroundColor: getContrastColor(theme.colors.primary),
                                        },
                                    ]}
                                >
                                    <Text style={[cartStyles.cartCloseButtonText, { color: theme.colors.primary }]}>
                                        Schließen
                                    </Text>
                                    <View
                                        style={[
                                            cartStyles.cartCloseButtonIcon,
                                            {
                                                backgroundColor: theme.colors.primary,
                                            },
                                        ]}
                                    >
                                        <Icon source="close" size={25} color={getContrastColor(theme.colors.primary)} />
                                    </View>
                                </Pressable>
                            )}
                            <ScrollView style={cartStyles.articleContainer}>
                                {this.props.cart.orderItems.length < 1 && (
                                    <Text
                                        style={[
                                            cartStyles.emptyCartText,
                                            { color: getContrastColor(theme.colors.primary) },
                                        ]}
                                    >
                                        Warenkorb ist leer
                                    </Text>
                                )}
                                {this.props.cart.orderItems.length > 0 && (
                                    <>
                                        {this.props.cart.orderItems.sort(sortByDayToCook).map((orderItem) => (
                                            <CartItem
                                                key={`${orderItem.entry.id}-${orderItem.dayToCook}`}
                                                orderItem={orderItem}
                                                removeOrderItem={removeOrderItem}
                                                windowWidth={windowWidth}
                                            />
                                        ))}
                                    </>
                                )}
                            </ScrollView>
                            {deliveryCountry.minOrderValue && (
                                <View
                                    style={[
                                        cartStyles.cartSummaryWrapper,
                                        {
                                            borderTopColor: getContrastColor(theme.colors.primary),
                                            color: getContrastColor(theme.colors.primary),
                                        },
                                    ]}
                                >
                                    <Text style={{ marginTop: 10, color: getContrastColor(theme.colors.primary) }}>
                                        {("Warenkorb: " + total.toFixed(2) + " €").replace(".", ",")}
                                    </Text>

                                    {((Number(deliveryCountry.deliveryFee) > 0 &&
                                        deliveryCountry.deliveryNeverFree) ||
                                        (Number(deliveryCountry.deliveryFee) > 0 &&
                                            deliveryCountry.deliveryFreeAt &&
                                            total <= Number(deliveryCountry.deliveryFreeAt))) && (
                                            <Text
                                                style={{
                                                    marginTop: 10,
                                                    marginBottom: 2,
                                                    color: getContrastColor(theme.colors.primary),
                                                }}
                                        >
                                            {"zzgl. Versandkosten: " +
                                                (Number(deliveryCountry.deliveryFee)
                                                          .toFixed(2)
                                                          .replace(".", ",")) +
                                                " €"}
                                        </Text>
                                    )}
                                    {deliveryCountry.deliveryFreeAt &&
                                        deliveryCountry.deliveryFreeAt > 0 &&
                                        total <= Number(deliveryCountry.deliveryFreeAt) && (
                                            <Text
                                                style={{
                                                    fontSize: 10,
                                                    color: getContrastColor(theme.colors.primary),
                                                }}
                                            >
                                                ab {deliveryCountry.deliveryFreeAt} € versandkostenfrei
                                            </Text>
                                        )}

                                    {minOrderCharge > 0 && (
                                        <Text
                                            style={{
                                                marginTop: 10,
                                                marginBottom: 2,
                                                color: getContrastColor(theme.colors.primary),
                                            }}
                                        >
                                            {"zzgl. Mindermengenzuschlag: " + minOrderCharge.replace(".", ",") + " €"}
                                        </Text>
                                    )}
                                    <Text style={{ marginTop: 10, color: getContrastColor(theme.colors.primary) }}>
                                        <b>
                                            {
                                                "Summe: " +
                                                    (
                                                        total +
                                                        (total < Number(deliveryCountry.deliveryFreeAt) 
                                                            ? Number(deliveryCountry.deliveryFee)
                                                            : 0) +
                                                        Number(minOrderCharge)
                                                    )
                                                        .toFixed(2)
                                                        .replace(".", ",") +
                                                    " €*"
                                                // + (Number(deliveryCountry.deliveryFreeAt) > 0 ? "**" : "*")
                                            }
                                        </b>
                                    </Text>

                                    <Text style={{ fontSize: 10, color: getContrastColor(theme.colors.primary) }}>
                                        {/* {Number(deliveryCountry.deliveryFreeAt) > 0 ? "**" : "*"} */}
                                        *Preise inkl. MwSt.
                                    </Text>
                                    {isDefaultCountry && (
                                        <Text style={{ fontSize: 10, color: getContrastColor(theme.colors.primary) }}>
                                            Zusatzkosten gelten für {deliveryCountry.name}
                                        </Text>
                                    )}
                                    {showMinOrderWarning && (
                                        <View
                                            style={{
                                                backgroundColor:
                                                    total > Number(deliveryCountry.minOrderValue)
                                                        ? "transparent"
                                                        : theme.colors.error,
                                                paddingTop: 7,
                                                paddingRight: 10,
                                                paddingBottom: 5,
                                                paddingLeft: 10,
                                                marginTop: 20,
                                                borderRadius: 7,
                                                textAlign: "center",
                                            }}
                                        >
                                            <Text
                                                style={{
                                                    color: getContrastColor(
                                                        total > Number(deliveryCountry.minOrderValue)
                                                            ? theme.colors.primary
                                                            : theme.colors.error
                                                    ),
                                                    fontSize: 12,
                                                    textAlign: "center",
                                                }}
                                            >
                                                {total > Number(deliveryCountry.minOrderValue)
                                                    ? "Mindestbestellwert erreicht"
                                                    : "Mindestbestellwert (" +
                                                      deliveryCountry.minOrderValue +
                                                      " €) nicht erreicht"}
                                            </Text>
                                        </View>
                                    )}
                                </View>
                            )}
                            <Button
                                onPress={this.props.toggleCustomerData}
                                mode="contained"
                                buttonColor={theme.colors.accent}
                                textColor={getContrastColor(theme.colors.accent)}
                                disabled={
                                    total === 0 ||
                                    (total < Number(deliveryCountry.minOrderValue) && minOrderCharge === 0)
                                }
                                style={cartStyles.cartButton}
                            >
                                Bestellen
                            </Button>
                        </View>
                    )}
                </Animated.View>
                {open && (
                    <Pressable onPress={onBackdropPress} style={StyleSheet.absoluteFill}>
                        <View
                            nativeID="cartBackground"
                            style={{ ...StyleSheet.flatten(styles.backdrop), backgroundColor: theme.colors.backdrop }}
                        ></View>
                    </Pressable>
                )}
            </Portal>
        );
    }
}

function mapStateToProps(state) {
    const { settings, account, cart } = state;
    return { settings, account, cart };
}

export default connect(mapStateToProps)(withTheme(Cart));
